import * as Contract from '@tableau/api-external-contract-js';
import { DashboardLayoutChangeDetails, TableauEventType, WorkbookFormatting } from '@tableau/api-external-contract-js';
import { DashboardLayoutEvent, NotificationId } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  DashboardImpl,
  DashboardObjectImpl,
  NotificationService,
  ServiceNames,
  SingleEventManager,
  SingleEventManagerImpl,
  WorksheetImpl,
} from '@tableau/api-shared-js';
import { DashboardLayoutChangedEvent } from '../Events/DashboardLayoutChangedEvent';
import { WorkbookFormattingChangedEvent } from '../Events/WorkbookFormattingChangedEvent';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';
import { DashboardObject } from './DashboardObject';
import { Sheet } from './Sheet';
import { Worksheet } from './Worksheet';

export class Dashboard extends Sheet implements Contract.ExtensionDashboard {
  public constructor(private _dashboardImpl: DashboardImpl) {
    super(_dashboardImpl);
    _dashboardImpl.initializeWithPublicInterfaces();
    this.initializeEvents().forEach((e) => this.addNewEventType(e));
  }

  public get worksheets(): Array<Contract.ExtensionWorksheet> {
    return this._dashboardImpl.worksheetsImpl.map((worksheetImpl: WorksheetImpl) => {
      return new Worksheet(worksheetImpl);
    });
  }

  public get objects(): Array<Contract.ExtensionDashboardObject> {
    return this._dashboardImpl.objects.map((dashboardObjectImpl: DashboardObjectImpl) => {
      return new DashboardObject(dashboardObjectImpl);
    });
  }

  public get activeDashboardObjectId(): number {
    return this._dashboardImpl.activeDashboardObjectId;
  }

  public get activeDashboardName(): string {
    return this._dashboardImpl.activeDashboardName;
  }

  public setZoneVisibilityAsync(zoneVisibilityMap: Contract.ZoneVisibilityMap): Promise<void> {
    return this._dashboardImpl.setDashboardObjectVisibilityAsync(zoneVisibilityMap);
  }

  public setDashboardObjectVisibilityAsync(dashboardObjectVisibilityMap: Contract.DashboardObjectVisibilityMap): Promise<void> {
    return this._dashboardImpl.setDashboardObjectVisibilityAsync(dashboardObjectVisibilityMap);
  }

  public getDashboardObjectById(dashboardObjectId: number): DashboardObject | undefined {
    const zone = this._dashboardImpl.getDashboardObjectById(dashboardObjectId);
    return zone && new DashboardObject(zone);
  }

  public initializeEvents(): Array<SingleEventManager> {
    const results = new Array<SingleEventManager>();

    // Initializing events and registering notification service
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return (allows unit testing without NotificationService mock)
      return results;
    }

    const dashboardLayoutChangedEvent = new SingleEventManagerImpl<DashboardLayoutChangedEvent>(TableauEventType.DashboardLayoutChanged);
    notificationService.registerHandler(
      NotificationId.DashboardLayoutChanged,
      () => true,
      (event: DashboardLayoutEvent) => {
        const dashboardObjectChanges: DashboardLayoutChangeDetails = this._dashboardImpl.updateZones(
          event.zones,
          event.activeZoneId,
          event.activeDashboardName,
        );
        dashboardLayoutChangedEvent.triggerEvent(() => new DashboardLayoutChangedEvent(dashboardObjectChanges));
      },
    );

    results.push(dashboardLayoutChangedEvent);

    const workbookFormattingChangedEvent = new SingleEventManagerImpl<WorkbookFormattingChangedEvent>(
      TableauEventType.WorkbookFormattingChanged,
    );

    notificationService.registerHandler(
      NotificationId.WorkbookFormattingChanged,
      () => true,
      (eventFormatting: WorkbookFormatting) => {
        workbookFormattingChangedEvent.triggerEvent(() => new WorkbookFormattingChangedEvent(eventFormatting));
      },
    );

    results.push(workbookFormattingChangedEvent);

    return results;
  }

  public moveAndResizeDashboardObjectsAsync(
    dashboardObjectPositionAndSizeUpdateArray: Contract.DashboardObjectPositionAndSizeUpdateArray,
  ): Promise<void> {
    return this._dashboardImpl.moveAndResizeDashboardObjectsAsync(dashboardObjectPositionAndSizeUpdateArray);
  }

  public replayAnimationAsync(replaySpeed: Contract.ReplaySpeedType): Promise<void> {
    return this._dashboardImpl.replayAnimationAsync(replaySpeed);
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._dashboardImpl.getFiltersAsync();
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._dashboardImpl.applyFilterAsync(fieldName, values, updateType, options);
  }
}
