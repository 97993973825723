import * as Contract from '@tableau/api-external-contract-js';

/**
 * Implementation of the external WorksheetContent namespace.
 * This does not follow the Impl pattern as WorksheetContent is
 * currently just a (single) property bag.
 */
export class WorksheetContent implements Contract.WorksheetContent {
  public constructor(private _worksheet: Contract.ExtensionWorksheet) {}

  public get worksheet(): Contract.ExtensionWorksheet {
    return this._worksheet;
  }
}
