import * as Contract from '@tableau/api-external-contract-js';
import {
  Column,
  FilterUpdateType,
  SelectionUpdateType,
  TableauEventType,
  TooltipContext,
  WorksheetFormatting,
} from '@tableau/api-external-contract-js';
import { FilterEvent, NotificationId, SummaryDataEvent, VisualId, WorksheetFormattingEvent } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  NotificationService,
  ServiceNames,
  SingleEventManager,
  SingleEventManagerImpl,
  TableauError,
  WorksheetImpl,
} from '@tableau/api-shared-js';
import { FilterChangedEvent } from '../Events/FilterChangedEvent';
import { MarksSelectedEvent } from '../Events/MarksSelectedEvent';
import { SummaryDataChangedEvent } from '../Events/SummaryDataChangedEvent';
import { WorksheetFormattingChangedEvent } from '../Events/WorksheetFormattingChangedEvent';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';
import { Dashboard } from './Dashboard';
import { Sheet } from './Sheet';

export class Worksheet extends Sheet implements Contract.ExtensionWorksheet {
  public constructor(protected _worksheetImpl: WorksheetImpl) {
    super(_worksheetImpl);

    // Call to initialize events and then call down to the event listener manager to handle things
    this.initializeEvents().forEach((e) => this.addNewEventType(e));
  }

  public get parentDashboard(): Contract.ExtensionDashboard {
    // This should never happen for Extensions if the DashboardImpls are created correctly
    if (this._worksheetImpl.parentDashboard == null) {
      throw new TableauError(Contract.SharedErrorCodes.ImplementationError, `Parent dashboard not implemented`);
    }

    return new Dashboard(this._worksheetImpl.parentDashboard);
  }

  public get backgroundColor(): string {
    if (this._worksheetImpl.backgroundColor == null) {
      throw new TableauError(Contract.SharedErrorCodes.ImplementationError, `Worksheet background color is not available`);
    }

    return this._worksheetImpl.backgroundColor;
  }

  public get formatting(): WorksheetFormatting {
    if (this._worksheetImpl.formatting == null) {
      throw new TableauError(Contract.SharedErrorCodes.ImplementationError, `Worksheet formatting information is not available`);
    }

    return this._worksheetImpl.formatting;
  }

  /**
   * Helper method which goes through and registers each event type this impl knows about
   * with the NotificationService. It returns an array of SingleEventManager objects which
   * can then be passed to an EventListenerManager to handle user registration / unregistration.
   *
   * @param {Worksheet} worksheet The worksheet object which will be included with the event notifications
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager
   */
  public initializeEvents(): Array<SingleEventManager> {
    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    // Initialize all of the event managers we'll need (one for each event type)
    const marksEvent = new SingleEventManagerImpl<MarksSelectedEvent>(TableauEventType.MarkSelectionChanged);
    notificationService.registerHandler(
      NotificationId.SelectedMarksChanged,
      (model) => {
        const visualId = model as VisualId;
        return this.visualIdsAreEqual(visualId, this._worksheetImpl.visualId);
      },
      () => marksEvent.triggerEvent(() => new MarksSelectedEvent(this)),
    );

    const filterEvent = new SingleEventManagerImpl<FilterChangedEvent>(TableauEventType.FilterChanged);
    notificationService.registerHandler(
      NotificationId.FilterChanged,
      (model) => {
        const filterEventResponse = model as FilterEvent;
        return this._worksheetImpl.visualId.worksheet === filterEventResponse.visualId.worksheet;
      },
      (event: FilterEvent) => {
        filterEvent.triggerEvent(() => new FilterChangedEvent(this, event.fieldName, event.fieldId));
      },
    );

    const summaryDataEvent = new SingleEventManagerImpl<SummaryDataChangedEvent>(TableauEventType.SummaryDataChanged);
    notificationService.registerHandler(
      NotificationId.SummaryDataChanged,
      (model) => {
        const summaryDataEventResponse = model as SummaryDataEvent;
        return this._worksheetImpl.visualId.worksheet === summaryDataEventResponse.visualId.worksheet;
      },
      (event: SummaryDataEvent) => {
        summaryDataEvent.triggerEvent(() => new SummaryDataChangedEvent(this));
      },
    );

    const worksheetFormattingChangedEvent = new SingleEventManagerImpl<WorksheetFormattingChangedEvent>(
      TableauEventType.WorksheetFormattingChanged,
    );

    notificationService.registerHandler(
      NotificationId.WorksheetFormattingChanged,
      (model) => {
        const worksheetFormattingChangedEvent = model as WorksheetFormattingEvent;
        return this._worksheetImpl.visualId.worksheet === worksheetFormattingChangedEvent.visualId.worksheet;
      },
      (eventFormatting: WorksheetFormatting) => {
        worksheetFormattingChangedEvent.triggerEvent(() => new WorksheetFormattingChangedEvent(this, eventFormatting));
      },
    );

    results.push(marksEvent);
    results.push(filterEvent);
    results.push(summaryDataEvent);
    results.push(worksheetFormattingChangedEvent);

    return results;
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._worksheetImpl.applyFilterAsync(fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRangeFilterAsync(fieldName, filterOptions);
  }

  public applyHierarchicalFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._worksheetImpl.applyHierarchicalFilterAsync(fieldName, values, updateType, options);
  }

  public applyRelativeDateFilterAsync(fieldName: string, options: Contract.RelativeDateFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRelativeDateFilterAsync(fieldName, options);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this._worksheetImpl.clearFilterAsync(fieldName);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._worksheetImpl.getDataSourcesAsync();
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._worksheetImpl.getFiltersAsync();
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getSelectedMarksAsync();
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getHighlightedMarksAsync();
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getSummaryDataAsync(options);
  }

  public getSummaryDataReaderAsync(pageRowCount: number, options: Contract.GetSummaryDataOptions): Promise<Contract.DataTableReader> {
    return this._worksheetImpl.getSummaryDataReaderAsync(pageRowCount, options);
  }

  public getSummaryColumnsInfoAsync(): Promise<Array<Column>> {
    return this._worksheetImpl.getSummaryColumnsInfoAsync();
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    console.warn(
      'Worksheet.getUnderlyingDataAsync is deprecated. Please use ' +
        'Worksheet.getUnderlyingTablesAsync and Worksheet.getUnderlyingTableDataAsync',
    );
    return this._worksheetImpl.getUnderlyingDataAsync(options);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._worksheetImpl.getUnderlyingTablesAsync();
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getUnderlyingTableDataAsync(logicalTableId, options);
  }

  public getUnderlyingTableDataReaderAsync(
    logicalTableId: string,
    pageRowCount: number,
    options: Contract.GetUnderlyingDataOptions,
  ): Promise<Contract.DataTableReader> {
    return this._worksheetImpl.getUnderlyingTableDataReaderAsync(logicalTableId, pageRowCount, options);
  }

  public getVisualSpecificationAsync(): Promise<Contract.VisualSpecification> {
    return this._worksheetImpl.getVisualSpecificationAsync();
  }

  public addMarksCardFieldsAsync(
    marksCardIndex: number,
    encodingType: Contract.EncodingType,
    columns: Array<Contract.Column>,
    startIndex: number,
  ): Promise<void> {
    return this._worksheetImpl.addMarksCardFieldsAsync(marksCardIndex, encodingType, columns, startIndex);
  }

  public moveMarksCardFieldAsync(marksCardIndex: number, fromIndex: number, toIndex: number, fieldCount = 1): Promise<void> {
    return this._worksheetImpl.moveMarksCardFieldAsync(marksCardIndex, fromIndex, toIndex, fieldCount);
  }

  public spliceMarksCardFieldsAsync(
    marksCardIndex: number,
    encodingType: Contract.EncodingType,
    startIndex: number,
    deleteCount: number,
    columns: Array<Contract.Column>,
  ): Promise<void> {
    return this._worksheetImpl.spliceMarksCardFieldsAsync(marksCardIndex, encodingType, startIndex, deleteCount, columns);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    return this._worksheetImpl.clearSelectedMarksAsync();
  }

  public selectMarksByIDAsync(marksInfo: Array<Contract.MarkInfo>, updateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(marksInfo, updateType);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>, selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByValueAsync(selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>, selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(selections, selectionUpdateType);
  }

  public annotateMarkAsync(mark: Contract.MarkInfo, annotationText: string): Promise<void> {
    return this._worksheetImpl.annotateMarkAsync(mark, annotationText);
  }

  public getAnnotationsAsync(): Promise<Array<Contract.Annotation>> {
    return this._worksheetImpl.getAnnotationsAsync();
  }

  public removeAnnotationAsync(annotation: Contract.Annotation): Promise<void> {
    return this._worksheetImpl.removeAnnotationAsync(annotation);
  }

  public hoverTupleAsync(hoveredTuple?: number, tooltip?: TooltipContext | null, allowHoverActions = true): Promise<void> {
    return this._worksheetImpl.hoverTupleAsync(hoveredTuple, tooltip, allowHoverActions);
  }

  public selectTuplesAsync(selectedTuples: Array<number>, selectOption: Contract.SelectOptions, tooltip?: TooltipContext): Promise<void> {
    return this._worksheetImpl.selectTuplesAsync(selectedTuples, selectOption, tooltip);
  }

  public getTooltipTextAsync(tupleId: number): Promise<String> {
    return this._worksheetImpl.getTooltipTextAsync(tupleId);
  }

  public leaveMarkNavigationAsync(): Promise<void> {
    return this._worksheetImpl.leaveMarkNavigationAsync();
  }

  public editAliasesDialogAsync(fieldName: string): Promise<void> {
    return this._worksheetImpl.editAliasesDialogAsync(fieldName);
  }

  private visualIdsAreEqual(a: VisualId, b: VisualId): boolean {
    return (
      a &&
      b &&
      a.worksheet === b.worksheet &&
      a.dashboard === b.dashboard &&
      a.storyboard === b.storyboard &&
      a.storyPointID === b.storyPointID
    );
  }
}
