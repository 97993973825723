import * as Contract from '@tableau/api-external-contract-js';
import { InternalToExternalEnumMappings as EnumMappings } from '@tableau/api-shared-js';
import { ClassNameKey, WorkbookFormatting } from '@tableau/api-internal-contract-js';

export class WorkbookFormattingEnvUtil {
  public static convertToWorkbookFormatting(workbookFormattingModel?: WorkbookFormatting): Contract.WorkbookFormatting | undefined {
    if (!workbookFormattingModel) {
      return undefined;
    } else {
      const formattingMap: Array<Contract.FormattingSheet> = workbookFormattingModel.formattingSheets.map((currentFormattingSheet) => {
        const formattingSheet: Contract.FormattingSheet = {
          classNameKey: EnumMappings.classNameKey.convert(currentFormattingSheet.classNameKey),
          cssProperties: getCssProperties(currentFormattingSheet.classNameKey, currentFormattingSheet.cssProperties),
        };
        return formattingSheet;
      });
      const result: Contract.WorkbookFormatting = {
        formattingSheets: formattingMap,
      };
      return result;
    }
  }
}

function getCssProperties(classNameKey: ClassNameKey, cssProperties: React.CSSProperties): import('react').CSSProperties {
  if (classNameKey === ClassNameKey.RowDividers || classNameKey === ClassNameKey.ColumnDividers) {
    return {
      borderColor: cssProperties.borderColor,
      borderStyle: cssProperties.borderStyle,
      borderWidth: cssProperties.borderWidth,
    } as React.CSSProperties;
  }

  return {
    fontFamily: cssProperties.fontFamily,
    fontSize: cssProperties.fontSize,
    fontWeight: cssProperties.fontWeight,
    fontStyle: cssProperties.fontStyle,
    textDecoration: cssProperties.textDecoration,
    color: cssProperties.color,
  };
}
